import React, { useRef, useEffect } from "react"
import Page from "src/components/page"
import Section from "src/components/section"
import { Link } from "gatsby"
import Markup from "src/components/markup"
import { useOrderMenuStore } from "src/state/order-menu"
import PillButton from "src/components/pill-button"
import { useAuthorStore } from "src/state/author"
import author from "src/images/author.png"
import { redCircle, greyCircle, yellowCircle } from "src/components/svgs"

const AuthorPage = () => {
  const { meta, section1, section2 } = useAuthorStore()
  const iframeRef = useRef()
  const { setShouldShowOrderMenu } = useOrderMenuStore()

  useEffect(() => {
    const iframe = iframeRef.current
    const resizeObserver = new ResizeObserver(() => {
      iframe.style.height = `${Math.floor(iframe.offsetWidth * (9 / 16))}px`
    })
    resizeObserver.observe(iframe)
    return () => resizeObserver.unobserve(iframe)
  })

  return (
    <Page {...meta}>
      <Section className="section-1">
        <div className="avatar-mobile">
          <Markup>{redCircle}</Markup>
          <img src={author} alt={section1.authorAlt} />
        </div>
        <h2 className="title parallax-0">
          <Markup>{section1.title}</Markup>
        </h2>
        <div className="details parallax-1">
          <div className="left">
            <div className="avatar">
              <Markup>{redCircle}</Markup>
              <img src={author} alt={section1.authorAlt} />
            </div>
            <div className="speaking parallax-0">
              <h3>{section1.speaking.title}</h3>
              <div className="buttons">
                <Link to="/contact">
                  <PillButton className="white-grey">
                    {section1.speaking.contactButtonText}
                  </PillButton>
                </Link>
                <a href={`mailto:${section1.speaking.email}`}>
                  <PillButton className="white-grey">
                    {section1.speaking.emailButtonText}
                  </PillButton>
                </a>
              </div>
            </div>
          </div>
          <div className="body parallax-3">
            <Markup>{greyCircle}</Markup>
            <div className="body-content">
              <Markup>{section1.body}</Markup>
            </div>
            <div className="speaking-mobile">
              <h3>{section1.speaking.title}</h3>
              <div className="buttons">
                <Link to="/contact">
                  <PillButton className="white-grey">
                    {section1.speaking.contactButtonText}
                  </PillButton>
                </Link>
                <a href={`mailto:${section1.speaking.email}`}>
                  <PillButton className="white-grey">
                    {section1.speaking.emailButtonText}
                  </PillButton>
                </a>
              </div>
            </div>
          </div>
        </div>
      </Section>
      <Section className="section-2">
        <h2 className="title parallax-0">
          <Markup>{section2.title}</Markup>
        </h2>
        <div className="content">
          <div className="left">
            <div className="body parallax-1">
              <Markup>{section2.body}</Markup>
            </div>
            <div className="circle">
              <div className="bg">
                <Markup>{yellowCircle}</Markup>
              </div>
              <div className="circle-content">
                <p className="circle-body">
                  <Markup>{section2.circleBody}</Markup>
                </p>
                <PillButton onClick={() => setShouldShowOrderMenu(true)} className="white-grey">
                  {section2.circleButtonText}
                </PillButton>
              </div>
            </div>
          </div>
          <div className="right">
            <div className="bg-circle">
              <Markup>{greyCircle}</Markup>
            </div>
            <div className="video">
              <iframe
                className="parallax-2"
                ref={iframeRef}
                src={section2.videoUrl}
                title="YouTube video player"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              />
            </div>
            <div className="circle-mobile">
              <div className="bg">
                <Markup>{yellowCircle}</Markup>
              </div>
              <div className="circle-content">
                <p className="circle-body">
                  <Markup>{section2.circleBody}</Markup>
                </p>
                <PillButton onClick={() => setShouldShowOrderMenu(true)} className="white-grey">
                  {section2.circleButtonText}
                </PillButton>
              </div>
            </div>
          </div>
        </div>
      </Section>
    </Page>
  )
}

export default AuthorPage
