import create from "zustand"

export const useAuthorStore = create((set) => ({
  meta: {
    id: "author",
    title: "About the Author - David A. Yovanno, CEO of impact.com",
    description:
      "About author David A. Yovanno, partnership automation expert, CEO of impact.com and host of the Partnership Economy Podcast",
  },
  section1: {
    title: "About <br/> David A. Yovanno",
    authorAlt: "author David A. Yovanno",
    speaking: {
      title: "Book Dave to speak at your virtual or in-person event",
      contactButtonText: "Contact form",
      emailButtonText: "Email us",
      email: "events@impact.com",
    },
    body: `
      <p>David A. Yovanno is the CEO of <a href='https://impact.com' target='_blank'>impact.com</a>, the global standard partnership management platform that makes it easy for businesses to create, manage, and scale an ecosystem of partnerships with the brands and communities that customers trust to make purchases, get information, and entertain themselves at home, at work, or on the go. Dave and <a href='https://impact.com' target='_blank'>impact.com</a> have been pioneers in establishing partnerships as a third channel for scalable and resilient revenue growth alongside sales and marketing.</p>
      <p>Dave has provided strategic leadership to SaaS companies in the technology vertical for more than two decades, previously serving as CEO of Marin Software, a San Francisco-based global leader in paid search SaaS technology; as president of technology solutions at Conversant, a diversified marketing services company; and as CEO of Gigya, a customer identity management platform. He has also served on the board of the Interactive Advertising Bureau and as a lieutenant and CIO in the United States Navy.</p>
    `,
  },
  section2: {
    title: "Passionate about partnerships",
    body: `<p>In a video featured on CBSNEWS.com and elsewhere, Dave was recently interviewed about the work impact.com is doing to help organizations maximize their role in the partnership economy.</p>`,
    circleBody: "Find out more about how partnerships are transforming how companies succeed",
    circleButtonText: "Buy now",
    videoUrl: "https://www.youtube.com/embed/CgiXuNb8eXc",
  },
}))
